<template>
  <div class="boxed-container">
    <nav class="navbar horizontal-menu navbar-fixed-top">
      <div class="navbar-inner">
        <div class="navbar-brand">
          <router-link to="/" class="logo">
            <img src="../assets/images/workdatabig.png" width="60%" alt="" class="hidden-xs">
            <img src="../assets/images/workdata-min.png" width="100%" alt="" class="visible-xs">
          </router-link>
        </div>
        <div class="navbar-mobile-clear"></div>
        <!-- <a href="https://github.com/Anjaxs/WebStack-vue" target="_blank">
             <img style="position: absolute; top: 0; right: 0; border: 0;"
                 src="https://s3.amazonaws.com/github/ribbons/forkme_right_darkblue_121621.png"
                 alt="Fork me on GitHub">
          </a> -->
      </div>
    </nav>

    <div class="page-container">
      <div class="main-content" style="">
        <div class="row">
          <div class="col-md-12">
            <div class="panel panel-default">
              <!-- 关于WorkData -->
              <h4 class="text-gray">近期更新内容</h4>
              <div class="panel-body">
                <div class="row">
                  <div class="col-sm-12">
                    <blockquote>
                      <p class="update">{{ date }}</p>
                      <p class="update">1.更新最新ChatGPT可用站点</p>
                      <p class="update">2.新增3个常用高频网站收录</p>
                      
                      <p class="update">3.调整聚合打开的方式，支持一键打开多个同类型网站</p>
                      <p class="update">2023-05-01 03:01:36</p>
                      <p class="update">WorkData 1.0.0 正式发布！</p>
                      <br />
                      <p>使用中有任何新功能需求、内容需求和想法建议都可以扫码提给我们，我们将倾听您的意见，认真探索，并作出行动</p>
                    </blockquote>
                  </div>
                </div>
                <!-- 关于站长 -->
                <h4 class="text-gray">微信扫码提意见：</h4>
                <img src="../assets/images/yijianbox_logo.jpg" alt="" id="yijianbox_logo">
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </div>
  </div>
</template>
  
<script>
import Footer from '../components/Footer.vue'

export default {
  name: 'About',
  components: {
    Footer
  },
  data() {
    return {
      authorImg: './assets/images/logos/viggoz.png',
      date: ''
    }
  },
  metaInfo() {
    return {
      title: '关于WorkData',
    };
  },
  created() {

    let aData = new Date();
    console.log(aData) //Wed Aug 21 2019 10:00:58 GMT+0800 (中国标准时间)
    let dateValue = aData.getFullYear() + "-" + (aData.getMonth() + 1) + "-" + aData.getDate();
    console.log(dateValue) //2019-8-20 
    this.date = dateValue
  }
}
</script>
  
<style>
.panel-body {
  margin: auto;
}

#yijianbox_logo {
  display: block;
  margin: auto;
  width: 200px;
  height: 200px;
}

.update {
  text-align: left !important;
}
</style>
  